import React from 'react'
import { Container } from 'react-bootstrap';
import EmConstrucao from '../../components/emConstrucao/EmConstrucao';


function UnderC() {
  return (
    <Container>
        <EmConstrucao/>
    </Container>
  )
}

export default UnderC
