import React from 'react'
import Homeapp from './pages/home'



function Magnify() {
  return (
    <div className="containeres d-flex justify-content-center align-items-center">
      <Homeapp/>
    </div>
  )
}

export default Magnify
