import React from 'react';

function Marcamed() {
    return ( 
        <iframe 
  id="frame" 
  className='w-100' 
  style={{height:'600px'}} 
  title='medcloud' 
  src="https://hospitalemcor.marcamed.com.br/login"
  frameborder="0"/>
     );
}

export default Marcamed;